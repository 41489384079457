body {
  font-family: 'Open Sans';
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'), url('./styles/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
