/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}
body {
  font-family: 'Open Sans';
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://atlas.backbone-international.com/static/media/Poppins-Regular.731a28a4.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin'), local('Roboto-Thin'), url(https://atlas.backbone-international.com/static/media/Robotothin.72de91db.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotothin.a11d03f7.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotothin.7faf23e1.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(https://atlas.backbone-international.com/static/media/Robotothinitalic.f31f3151.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotothinitalic.d48c130d.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotothinitalic.e482899c.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'), url(https://atlas.backbone-international.com/static/media/Robotolight.38e1ed9a.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotolight.611ac983.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotolight.63d6f579.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(https://atlas.backbone-international.com/static/media/Robotolightitalic.90c562ab.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotolightitalic.692f9938.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotolightitalic.4561dd5b.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url(https://atlas.backbone-international.com/static/media/Roboto.69eee0b4.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Roboto.58bf8e0b.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Roboto.c613ca81.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Italic'), local('Roboto-Italic'), url(https://atlas.backbone-international.com/static/media/Robotoitalic.11699010.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotoitalic.3dccb571.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotoitalic.3a40d393.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url(https://atlas.backbone-international.com/static/media/Robotomedium.4a34361b.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotomedium.6422eac7.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotomedium.38aa81d4.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url(https://atlas.backbone-international.com/static/media/Robotomediumitalic.305c37ad.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotomediumitalic.05f1613d.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotomediumitalic.f5cbcf64.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url(https://atlas.backbone-international.com/static/media/Robotobold.15a913e1.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotobold.7bf74824.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotobold.1fc6a83d.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(https://atlas.backbone-international.com/static/media/Robotobolditalic.932c3a00.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotobolditalic.795c4a46.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotobolditalic.8ec9e467.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black'), local('Roboto-Black'), url(https://atlas.backbone-international.com/static/media/Robotoblack.202f9d52.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotoblack.8a7ab9f2.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotoblack.bf7b513a.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url(https://atlas.backbone-international.com/static/media/Robotoblackitalic.425a3fc5.woff2) format('woff2'), url(https://atlas.backbone-international.com/static/media/Robotoblackitalic.548e05d3.woff) format('woff'), url(https://atlas.backbone-international.com/static/media/Robotoblackitalic.ca9c9a75.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-size: 12px;
}

